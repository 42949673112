import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WelcomeStyles from "../scss/components/welcome.module.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={WelcomeStyles.welcome}>
      <h1 className={WelcomeStyles.heading}>
        <span className={WelcomeStyles.fade} aria-hidden="true">&lt;h1&gt;</span>
        Nothing Found!
        <span className={WelcomeStyles.fade} aria-hidden="true">&lt;/h1&gt;</span>
      </h1>
      <p>
        <span className={WelcomeStyles.fade} aria-hidden="true">&lt;p&gt;</span>
          You just hit a route that doesn&#39;t exist... the sadness.
        <span className={WelcomeStyles.fade} aria-hidden="true">&lt;/p&gt;</span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
